<route>
{
  "meta": {
    "auth": "WriteOffDetail"

  }
}
</route>

<template>
  <div>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>
      </div>

      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="orderId" label="订单号"> </el-table-column>
        <el-table-column align="center" prop="productName" label="商品名称"> </el-table-column>

        <el-table-column align="center" prop="settleMoney" label="订单金额(元）"> </el-table-column>

        <el-table-column align="center" prop="skuMoney" label="核销金额(元)"> </el-table-column>
        <el-table-column align="center" label="核销信息">
          <template slot-scope="scope">
            <div>核销码: {{ scope.row.skuId }}</div>
            <div>核销时间: {{ scope.row.checkTime | formatTime }}</div>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="checkBusinessUserName" label="核销人员"> </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],

  data() {
    return {
      searchValue: '',
      list: [],
      vendorDetail: {}
    }
  },
  methods: {
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize
        }
      }
      this.$api.financial
        .financial('getCheckRecordDetail', params, this.$route.query.id)
        .then(res => {
          this.vendorDetail = res
          this.list = res.records
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.query {
  margin-top: 20px;
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  div {
    line-height: 30px;

    .intro {
      display: inline-block;
      width: 100px;
      color: #999;
    }
  }
}
</style>
